import React, { FC } from 'react';

import Main from 'src/components/Main';
import Header from 'src/components/Header';
import NotFound from 'src/components/NotFound';

const NotFoundPage: FC = () => {
  return (
    <>
      <Header isSurvey />
      <Main>
        <NotFound
          title="Puslapis nerastas"
          text="Pasiklydome... Grįžkite atgal ir tęskite kelionių paiešką"
          is404
        />
      </Main>
    </>
  );
};

export default NotFoundPage;
